import React, { ComponentType } from 'react';
import { NavLink, NavLinkProps, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { combineClassNames } from '@utils/combineClassNames';
import Icon from '../../icon';
import Badge from '@common/components/badge';
import { EStatus } from '@common/definitions';
import { EPulseSurveyStatus } from '@modules/survey/types/objects';
import { ESurveyStatus } from '@modules/survey/definitions';
import Spinner from '@common/components/spinner';

export const LOADING_BREADCRUMB = 'loading...';

interface Tab extends NavLinkProps {
  name: string;
  to: string;
  count?: number;
  exact?: boolean;
}

export type Breadcrumb = {
  name: string;
  path?: string;
  onClick?: () => void;
  mask?: boolean;
};

type Props = {
  className?: string;
  children?: JSX.Element;
  title?: React.ReactNode;
  count?: number;
  image?: string;
  badge?: EStatus | EPulseSurveyStatus | ESurveyStatus;
  form?: ComponentType<any>;
  formProps?: Record<string, unknown>;
  breadcrumbs?: (Breadcrumb | undefined)[];
  description?: string | React.ReactNode;
  learnMoreHref?: string;
  searchbar?: JSX.Element,
  action?: unknown;
  progress?: number;
  mask?: boolean;
  tabs?: (Tab | undefined)[];
  fullWidth?: boolean;
};

type DefaultFormProps = { children: JSX.Element };
const DefaultForm = ({ children }: DefaultFormProps) => children;

export const TopNavigationBar = ({
  className,
  children,
  form: Form,
  formProps,
  image,
  badge,
  title,
  count,
  breadcrumbs,
  description,
  learnMoreHref,
  searchbar,
  action,
  tabs,
  progress,
  mask,
  fullWidth,
}: Props) => {
  const history = useHistory();
  const FormComponent = Form || DefaultForm;

  const actualBreadcrumbs = (breadcrumbs && breadcrumbs.filter((b) => !!b)) as (undefined | Breadcrumb[]);

  const containerClassName = combineClassNames('Container__Content', 'TopNavigationBar__Container', {
    'TopNavigationBar__Container--FullWidth': fullWidth,
  });

  return (
    <div className={combineClassNames('TopNavigationBar', className)}>
      {actualBreadcrumbs && (
        <div className="TopNavigationBar__Breadcrumb">
          <div className="Container__Content">
            {actualBreadcrumbs.map((item, i) => (
              item.name === LOADING_BREADCRUMB ? <Spinner /> : (
                <React.Fragment key={item.name}>
                  {i > 0 && <Icon type="chevron_right" />}
                  {item.path || item.onClick
                  ? (
                    <a
                      className={combineClassNames({ 'fs-mask': !!item.mask })}
                      onClick={item.onClick || (() => item.path && history.push(item.path))}
                    >
                      {item.name}
                    </a>
                  )
                  : (
                    <span
                      className={combineClassNames({ 'fs-mask': !!item.mask })}
                    >
                      {item.name}
                    </span>
                  )}
                </React.Fragment>
              )
            ))}
          </div>
        </div>
      )}
      <div className={containerClassName}>
        <div className="TopNavigationBar__Row">
          {(title || description) ? (
            <FormComponent {...formProps}>
              <div className={`TopNavigationBar__Row__Info${Form ? ' TopNavigationBar__Row__Info--form' : ''}`}>
                <div className="Align Align--no-wrap Align--center">
                  {image !== undefined && (
                    <div className="TopNavigationBar__Row__Info__Image fs-exclude">
                      {image
                        // @ts-expect-error
                        ? <img src={image} alt={title} />
                        : <Icon type="image" />}
                    </div>
                  )}
                  <h2 className={combineClassNames({ 'fs-mask': !!mask })}>
                    {title}
                    {count !== undefined && <span className="TopNavigationBar__Count">{` (${count})`}</span>}
                    {learnMoreHref && (
                      <a href={learnMoreHref} target="_blank" rel="noreferrer">
                        <Trans i18nKey="core:learn_more" />
                      </a>
                    )}
                  </h2>
                  {badge && <Badge status={badge} />}
                </div>
                {description && <div className="TopNavigationBar__Row__Info__Description">{description}</div>}
              </div>
            </FormComponent>
          ) : <div className="TopNavigationBar__Row__Info" />}
          {searchbar && <div className="TopNavigationBar__SearchBar">{searchbar}</div>}
          {action}
        </div>
        {children}
        {tabs && (
          <div className="TopNavigationBar__Tabs">
            {tabs
              .reduce<React.ReactNode[]>((acc, tab) => {
              if (!tab) return acc;

              const { to, name, count: tabCount, ...props } = tab;

              return [
                ...acc,
                <NavLink
                  key={to}
                  to={to}
                  {...props}
                  className="TopNavigationBar__Tabs__Item"
                  activeClassName="TopNavigationBar__Tabs__Item--active"
                >
                  {name}
                  {tabCount !== undefined && ` ${tabCount}`}
                </NavLink>,
              ];
            }, [])}
          </div>
        )}
        {(
          typeof progress === 'number' &&
          progress === progress // NaN check
        ) && (
          <div className="TopNavigationBar__Progress">
            {`${progress}%`}
            <div className="TopNavigationBar__Progress__Bar">
              <div className="TopNavigationBar__Progress__Bar__Fill" style={{ width: `${progress}%` }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
